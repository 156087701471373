import { Carousel } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import './Hint.css';

const Item = styled(Box)(() => ({
    margin: 0,
    height: '70px',
    color: '#000',
    lineHeight: '70px',
    textAlign: 'left',
    fontSize: '30px',
}));

export const Hint = ({ hints }) => (
    <>
        {hints.map((hint, index) => (
            <Item
                key={`${hint}`}
            >
                測試資料 {index + 1} 提示：{hint}
            </Item>
        ))}
    </>
);
