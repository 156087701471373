import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';

import { QuestionCircleOutlined } from '@ant-design/icons';

import {
    AccountNavLink,
    Header,
    Logo,
    MainNavLink,
    ManualNavLink,
} from '../../components/layout/header';
import { UserMenu } from '../../components/menu';
import ROUTES from '../../router/routes';
import { useGetMyDetailQuery } from '../../store/services/restApi';
import { selectIsAuthenticated } from '../../store/slices/authSlice';
import { Breadcrumb } from '../../components/layout/Breadcrumb';

const mainNavLinks = [
    ROUTES.COURSES,
    ROUTES.PROBLEMS,
];

const userNavLinks = [
    ROUTES.MY_COURSES,
    ROUTES.MY_PROBLEMS,
    ROUTES.LOGOUT,
];

const accountNavLinks = [
    ROUTES.LOGIN,
    ROUTES.REGISTER,
];

export const HeaderContainer = () => {
    const { data: userDetail = {} } = useGetMyDetailQuery();

    const isAuthenticated = useSelector(selectIsAuthenticated);

    const username = userDetail.username || '';

    return (
        <Header>
            <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                <Link to={ROUTES.HOME.link} style={{ color: 'white' }}>
                    <Logo />
                </Link>
                <MainNavLink
                    items={mainNavLinks}
                />
                <ManualNavLink
                    items={[{
                        text: <Breadcrumb />,
                    }]}
                />
                <ManualNavLink
                    items={[{
                        text: <QuestionCircleOutlined />,
                        link: ROUTES.MANUAL.link,
                    }]}
                />
                {
                    isAuthenticated
                        ? (
                            <UserMenu
                                items={userNavLinks.map(({ text, link }) => ({
                                    key: link,
                                    label: text,
                                    link,
                                }))}
                                username={username}
                            />
                        )
                        : (
                            <AccountNavLink
                                items={accountNavLinks}
                            />
                        )
                }
            </Box>
        </Header>
    );
};
