import { Provider } from 'react-redux';

import { ConfigProvider } from 'antd';

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { Router } from './router/Router';
import { store } from './store/store';
import antdTheme from './theme/antdTheme';
import muiTheme from './theme/muiTheme';
import { BreadcrumbProvider } from './components/layout/BreadcrumbContext';

const App = () => (
    <>
        <CssBaseline />
        <Provider store={store}>
            <ThemeProvider theme={muiTheme}>
                <ConfigProvider theme={antdTheme}>
                    <BreadcrumbProvider>
                        <Router />
                    </BreadcrumbProvider>
                </ConfigProvider>
            </ThemeProvider>
        </Provider>
    </>
);

export default App;
