import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import {
    Button,
    Spin,
    Table,
    Tooltip,
} from 'antd';

import { styled } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { Input } from '../../../components/input';
import { CheckBoxAutoComplete } from '../../../components/select';
import { AlertWindow } from '../../../components/window/AlertWindow';
import ROUTES from '../../../router/routes';
import {
    useGetAllCoursesQuery,
    useJoinCourseMutation,
} from '../../../store/services/restApi';
import { selectIsAuthenticated } from '../../../store/slices/authSlice';
import { basePalette } from '../../../theme/baseTheme';
import { MEMBER_STATUS } from '../constants';
import { useBreadcrumb } from '../../../components/layout/BreadcrumbContext';

const TableTitle = styled(Typography)({
    marginLeft: '10%',
});

const columns = [
    {
        title: <TableTitle>課程名稱</TableTitle>,
        dataIndex: 'name',
        key: 'name',
        width: '50%',
        render: (text, record) => {
            const linkProps = record.memberStatus === MEMBER_STATUS.JOINED
                ? {
                    component: Link,
                    to: `${ROUTES.COURSES.link}/${record.id}`,
                }
                : {};
            return (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <TableTitle {...linkProps}>
                    {text}
                </TableTitle>
            );
        },
    },
    {
        title: <TableTitle>教師</TableTitle>,
        dataIndex: 'owner',
        key: 'owner',
        width: '35%',
        render: (text) => <TableTitle>{text}</TableTitle>,
    },
    {
        title: '',
        dataIndex: 'memberStatus',
        key: 'memberStatus',
        width: '15%',
        render: (status, record) => {
            if (status === null) {
                return (
                    record.isAuthenticated
                        ? <Button onClick={record.onJoin}>加入</Button>
                        : (
                            <Tooltip title="請先登入" placement="bottomLeft">
                                <Button disabled>
                                    加入
                                </Button>
                            </Tooltip>
                        )
                );
            }
            return status;
        },
    },
];

export const CourseListContainer = () => {
    const navigate = useNavigate();
    const {
        data: allCourses = [],
        isLoading: allCoursesIsFetching,
        refetch: refetchAllCourses,
    } = useGetAllCoursesQuery();
    const [
        joinCourse,
        {
            data: joinResponse,
            isSuccess: joinSuccess,
            isLoading: joiningCourse,
            isError: joinFailed,
            error: joinError,
        },
    ] = useJoinCourseMutation();

    const isAuthenticated = useSelector(selectIsAuthenticated);

    const [teachers, setTeachers] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const { setBreadcrumbs } = useBreadcrumb();

    const handleJoin = (courseId) => {
        joinCourse({ courseId });
        const course = allCourses.filter((c) => `${c.id}` === `${courseId}`)[0];
        if (!course.isPrivate) {
            navigate(`${ROUTES.COURSES.link}/${courseId}`);
            setIsOpen(false);
        }
    };

    const tableData = allCourses
        .map((course) => ({
            ...course,
            key: course.id,
            owner: course.owner.username,
            memberStatus: course.status,
            isAuthenticated,
            onJoin: () => {
                handleJoin(course.id);
            },
        }))
        .filter((course) => {
            if (teachers.length === 0) {
                return true;
            }
            return teachers.includes(course.owner);
        })
        .filter((course) => {
            const text = (keyword || '').trim().toLowerCase();

            if (text === '') {
                return true;
            }
            const isInName = course.name.toLowerCase().includes(text);
            const isInOwner = course.owner.toLowerCase().includes(text);
            return isInName || isInOwner;
        });

    const allOwners = [
        ...new Set(allCourses.map((course) => course.owner.username)),
    ];

    const alertType = joinSuccess ? 'success' : 'error';
    const message = joinSuccess ? joinResponse.status : joinError?.message;

    useEffect(() => {
        refetchAllCourses();
    }, []);

    useEffect(() => {
        if (joinSuccess || joinFailed) {
            setIsOpen(true);
        }
    }, [joinSuccess, joinFailed]);

    useEffect(() => {
        setBreadcrumbs([
            {
                title: '課程',
                path: '/courses',
            },
        ]);
    }, []);

    const handleTeacherSearch = (v) => {
        setTeachers(v);
    };

    const handleKeywordSearch = (v) => {
        setKeyword(v);
    };

    return (
        <>
            <Grid
                container
                spacing={3}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Grid item xs={3} sx={{ marginTop: '16px' }}>
                    <CheckBoxAutoComplete
                        label="搜尋教師"
                        placeholder="搜尋教師"
                        options={allOwners}
                        onChange={handleTeacherSearch}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Input
                        label="搜尋關鍵字"
                        onChange={handleKeywordSearch}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={2} sx={{ marginTop: '16px' }} />
            </Grid>
            <Spin spinning={allCoursesIsFetching || joiningCourse}>
                <Table
                    dataSource={tableData}
                    columns={columns}
                    size="middle"
                    pagination={{ pageSize: 7 }}
                    style={{
                        width: '100%',
                        boxShadow: `0 0 8px ${basePalette.primary_dark}`,
                        borderRadius: '10px',
                        padding: '8px',
                        marginTop: '16px',
                    }}
                />
            </Spin>
            <AlertWindow
                message={message}
                type={alertType}
                visible={isOpen}
                onOk={() => setIsOpen(false)}
            />
        </>
    );
};
